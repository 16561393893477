@import-normalize;
@import "src/scss/variables";

@font-face {
  font-family: "FSMe";
  src: url("./fonts/FSMeWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FSMe-Bold";
  src: url("./fonts/FSMeWeb-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FSMe-Light";
  src: url("./fonts/FSMe-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-size: 1.75em;
}

a:link {
  text-decoration: none;
}

a:active {
  color: inherit;
}

.no-margin-top {
  margin-top: 0;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.nameWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
}

.userIcon {
  border: 50%;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background: $secondary linear-gradient(0deg, $purple1 0%, $purple2 100%);
  color: white;
  font-size: 1.5em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
